import { Formik } from "formik";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import SelectDropdown from "../../../molecules/Dropdown";
import CustomButton from "../../../atoms/CustomButton";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getData, postFormData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import CustomDropzone from "../../../molecules/uploadFile";
import FinancialDataTable from "../../../organisms/viewDataTable/index";
import BackBtn from "../../../atoms/BackBtn";

const AddFinancialData = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [rows, setRows] = useState([0]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  // const validationSchema = Yup.object().shape({
  //   companyname: Yup.string().required("Name is Required"),
  // });
  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company is required"),
    sheetType: Yup.string().required("Sheet Type is required"),
    periodType: Yup.string().required("Period Type is required"),
    auditStatus: Yup.string().required("Audit Status is required"),
    // currencyType: Yup.string().required("Currency is required"),
    unitType: Yup.string().required("Unit is required"),
    file: Yup.mixed().required("File is required"),
  });

  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [sheetType, setSheetType] = useState(null);
  const [auditStatus, setAuditStatus] = useState(null);
  const [periodType, setPeriodType] = useState(null);
  const [unit, setUnit] = useState(null);
  const [currency, setCurrency] = useState(null);

  const getCompanyList = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "company/getCompanyList",
        token: cookies.t,
      });

      if (res) {
        setFormData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [id]);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  const companyNameData = formData?.data?.map((company) => ({
    value: company._id,
    label: company.name,
  }));

  const initialValues = {
    company: "",
    sheetType: "",
    periodType: "",
    auditStatus: "",
    currencyType: "",
    unitType: "",
    file: null,
  };
  const getCurrencyByCompanyId = useCallback(async (companyId) => {
    try {
      const res = await getData({
        endpoint: "company/getCurrencyByCompanyId",
        params: {
          companyId: companyId,
        },
        token: cookies.t,
      });

      if (res) {
        setCurrency(res?.currency);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true); // Set loading to true when API call starts

    const payload = {
      sheetType: values.sheetType,
      periodType: values.periodType,
      auditStatus: values.auditStatus,
      units: values.unitType,
      currency: values.currencyType,
      file: fileData,
    };
    // setCurrency(values.currencyType);
    setUnit(values.unitType);

    try {
      const res = await postFormData({
        endpoint: "extract",
        params: {
          companyId: values.company,
        },
        token: cookies.t,
        data: payload,
      });

      if (res) {
        setTableData(res?.data?.data?.data);
        setCompanyId(res?.data?.data?.companyId); // Set companyId from response
        setSheetType(res?.data?.data?.sheetType); // Set dataId from response
        setPeriodType(res?.data?.data?.periodType); // Set periodType from response
        setAuditStatus(res?.data?.data?.auditStatus); // Set auditStatus from response
        setShowTable(true);
        const currency = await getCurrencyByCompanyId(values.company);
        if (currency) {
          setCurrency(currency); // Set the currency state from the response
        }
      }

      if (res?.data?.status) {
        toast.success("Financial Data Extracted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message || "Failed to add financial data", {
          style: errorStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.error("Error adding financial data:", error);
      // toast.error("An error occurred while adding financial data", {
      //   style: errorStyles,
      //   duration: 1000,
      // });
      toast.error(error?.error?.response?.data?.error, {
        duration: 1000,
        style: errorStyles,
      });
    } finally {
      setIsLoading(false); // Set loading to false when API call completes
    }
  };

  const handleCancel = async (
    companyId,
    auditStatus,
    periodType,
    year,
    sheetType,
  ) => {
    try {
      const res = await getData({
        endpoint: "company/getFilteredFinancialData",
        params: {
          companyId: companyId,
          sheetType: sheetType,
          periodType: periodType,
          auditStatus: auditStatus,
          yearsArray: null,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
        setAuditStatus(res?.data?.auditStatus);
        setPeriodType(res?.data?.periodType);
        setSheetType(res?.data?.sheetType);
        setShowTable(true);
      }

      // if (res.status) {
      //   toast.success(res.message, {
      //     style: confimationStyles,
      //     duration: 1000,
      //   });
      // } else {
      //   toast.error(res.message, { style: errorStyles, duration: 1000 });
      // }
    } catch (error) {
      console.error("Error canceling changes:", error);
    }
  };

  return (
    <>
      <div style={{ width: "20px", marginBottom: "10px" }}>
        <BackBtn />
      </div>
      <PageHeader title="Add Financial Data" />
      <PageCard>
        <Formik
          key={Math.random}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            isSubmitting,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Company</label>
                    <SelectDropdown
                      data={companyNameData}
                      placeholder="Select Company"
                      onChange={(option) => setFieldValue("company", option)}
                      isClearable={true}
                      selectedValue={values.company}
                      onClear={() => setFieldValue("company", " ")}
                      onBlur={() => setFieldTouched("company", true)}
                    />
                    {touched.company && errors.company && (
                      <div className="error-message">{errors.company}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Audit Status</label>
                    <SelectDropdown
                      data={[
                        {
                          value: "audited",
                          label: "Audited",
                        },
                        { value: "unaudited", label: "Unaudited" },
                      ]}
                      placeholder="Select Audit Status"
                      onChange={(option) =>
                        setFieldValue("auditStatus", option)
                      }
                      isClearable={true}
                      selectedValue={values.auditStatus}
                      onClear={() => setFieldValue("auditStatus", " ")}
                    />
                    {touched.auditStatus && errors.auditStatus && (
                      <div className="error-message">{errors.auditStatus}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="periodType">Period Type</label>
                    <SelectDropdown
                      data={[
                        { value: "yearly", label: "Yearly" },
                        { value: "halfYearly", label: "Half Yearly" },
                        { value: "quarterly", label: "Quarterly" },
                        { value: "monthly", label: "Monthly" },
                      ]}
                      placeholder="Select Period Type"
                      onChange={(option) => setFieldValue("periodType", option)}
                      isClearable={true}
                      selectedValue={values.periodType}
                      onClear={() => setFieldValue("periodType", " ")}
                    />
                    {touched.periodType && errors.periodType && (
                      <div className="error-message">{errors.periodType}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Sheet Type</label>
                    <SelectDropdown
                      data={[
                        {
                          value: "balance_sheet",
                          label: "Balance Sheet",
                        },
                        { value: "pl", label: "P&L" },
                        // { value: "other", label: "Other" },
                      ]}
                      placeholder="Select Sheet Type"
                      onChange={(option) => setFieldValue("sheetType", option)}
                      isClearable={true}
                      selectedValue={values.sheetType}
                      onClear={() => setFieldValue("sheetType", " ")}
                    />
                    {touched.sheetType && errors.sheetType && (
                      <div className="error-message">{errors.sheetType}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="currencyType">Currency</label>
                    <SelectDropdown
                      data={[
                        { value: "INR", label: "INR" },
                        { value: "USD", label: "USD" },
                        { value: "EUR", label: "EUR" },
                        { value: "GBP", label: "GBP" },
                        { value: "AED", label: "AED" },
                        // { value: "GBP", label: "GBP" },
                        // { value: "JPY", label: "JPY" },
                        // { value: "CNY", label: "CNY" },
                        // { value: "AUD", label: "AUD" },
                        // { value: "CAD", label: "CAD" },
                      ]}
                      placeholder="Select Currency"
                      onChange={(option) =>
                        setFieldValue("currencyType", option)
                      }
                      isClearable={true}
                      selectedValue={values.currencyType}
                      onClear={() => setFieldValue("currencyType", " ")}
                    />
                    {touched.currencyType && errors.currencyType && (
                      <div className="error-message">{errors.currencyType}</div>
                    )}
                  </div> */}
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="unitType">Units</label>
                    <SelectDropdown
                      data={[
                        { value: "Actual", label: "Actual" },
                        { value: "Hundreds", label: "Hundreds" },
                        { value: "Thousands", label: "Thousands" },
                        { value: "Lakhs", label: "Lakhs" },
                        { value: "Crore", label: "Crore" },
                        { value: "Million", label: "Million" },
                        { value: "Billion", label: "Billion" },
                      ]}
                      placeholder="Select Unit"
                      onChange={(option) => setFieldValue("unitType", option)}
                      isClearable={true}
                      selectedValue={values.unitType}
                      onClear={() => setFieldValue("unitType", " ")}
                    />
                    {touched.unitType && errors.unitType && (
                      <div className="error-message">{errors.unitType}</div>
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-12 col-md-12">
                    <CustomDropzone
                      // onFileSelect={(file) => setFileData(file)}
                      onFileSelect={(file) => {
                        setFileData(file); // Optional: If you still need to manage fileData separately
                        setFieldValue("file", file); // Set Formik's 'file' field
                      }}
                      description="Supported File type : Pdf and Image"
                    />
                    {touched.file && errors.file && (
                      <div className="error-message">{errors.file}</div>
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-12 col-md-12">
                    <CustomButton
                      type="btn-primary"
                      disabled={isLoading || !isValid || !dirty}
                      iconRequired={false}
                      handleClick={() => handleSubmit(values)}
                    >
                      Submit
                      {isLoading && (
                        <div
                          className="spinner-border spinner-border-sm text-light ms-3"
                          role="status"
                        ></div>
                      )}
                    </CustomButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </PageCard>

      {showTable && (
        <PageCard classes="mt-3">
          <FinancialDataTable
            financialData={tableData}
            handleCancel={handleCancel}
            companyId={companyId}
            sheetType={sheetType}
            periodType={periodType}
            auditStatus={auditStatus}
            excurrency={currency}
            exunit={unit}
            extracted={true}
          />
        </PageCard>
      )}
    </>
  );
};
export default AddFinancialData;
