import React, { useEffect, useState } from "react";
import "./styles.scss";
import toast from "react-hot-toast";
import { deleteData, getData, putData } from "../../../services";
import { confimationStyles, errorStyles } from "../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import Delete from "../../icons/Delete";
import SelectDropdown from "../../molecules/Dropdown";
import CustomButton from "../../atoms/CustomButton";
import DeleteModal from "../modals/DeleteModal";
import Add from "../../icons/Add";
import Modal from "../modals/Modal";
import axios from "axios";
import { formatValue } from "../../../helpers/formatValue";

// Conversion rates
// const conversionRates = {
//   INR: {
//     Billion: 0.00001,
//     Crore: 0.0001,
//     Million: 0.001,
//     Lakhs: 0.01,
//     Thousands: 1,
//     Hundreds: 10,
//   },
//   USD: {
//     Billion: 0.0000012,
//     Crore: 0.0000012,
//     Million: 0.000012,
//     Lakhs: 0.00012,
//     Thousands: 0.012,
//     Hundreds: 0.12,
//   },
//   EUR: {
//     Billion: 0.0000011,
//     Crore: 0.0000011,
//     Million: 0.000011,
//     Lakhs: 0.00011,
//     Thousands: 0.011,
//     Hundreds: 0.11,
//   },
//   GBP: {
//     Billion: 0.0000009,
//     Crore: 0.0000009,
//     Million: 0.000009,
//     Lakhs: 0.00009,
//     Thousands: 0.009,
//     Hundreds: 0.09,
//   },
//   AED: {
//     Billion: 0.0000044,
//     Crore: 0.0000044,
//     Million: 0.000044,
//     Lakhs: 0.00044,
//     Thousands: 0.044,
//     Hundreds: 0.44,
//   },
// };

const currencies = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "AED", label: "AED" },
];

const units = [
  { value: "Actual", label: "Actual" },
  { value: "Hundreds", label: "Hundreds" },
  { value: "Thousands", label: "Thousands" },
  { value: "Lakhs", label: "Lakhs" },
  { value: "Crore", label: "Crore" },
  { value: "Million", label: "Million" },
  { value: "Billion", label: "Billion" },
];

const FinancialDataTable = ({
  financialData,
  handleCancel,
  sheetType,
  auditStatus,
  periodType,
  companyId,
  edit = true,
  onDataConverted1,
  onDataConverted2,
  preview,
  year,
  exunit,
  excurrency,
  view,
  unitsNew,
  currencyNew,
  extracted,
}) => {
  const [cookies] = useCookies(["t"]);
  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState(financialData?.data);
  const [currency, setCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const [newMetrics, setNewMetrics] = useState([]);
  const [isLoadingDel, setIsLoadingDel] = useState(false); // Add loading state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "Financial Data",
  });
  const [editingYears, setEditingYears] = useState({});
  const [isAddMetricModalOpen, setIsAddMetricModalOpen] = useState(false);
  const [newMetricType, setNewMetricType] = useState("1");
  const [nestedLevels, setNestedLevels] = useState(1);
  const [isAddNestedRowModalOpen, setIsAddNestedRowModalOpen] = useState(false);
  const [newNestedRowType, setNewNestedRowType] = useState("1");
  const [currentParentKey, setCurrentParentKey] = useState("");
  const [thousandsRates, setThousandsRates] = useState({});

  // const currencies = Object.keys(thousandsRates)?.map((key) => ({
  //   value: key,
  //   label: key,
  // }));

  useEffect(() => {
    const fetchCurrencyConversionRates = async () => {
      try {
        const response = await axios.get(
          "https://open.er-api.com/v6/latest/INR",
        );

        if (response) {
          setThousandsRates(response?.data?.rates);
          // setConversionRates(response?.data?.rates);
        }
      } catch (error) {
        console.error("Error fetching currency conversion rates:", error);
      }
    };

    fetchCurrencyConversionRates();
  }, []);

  const conversionRates = {};

  // for (const currency in thousandsRates) {
  //   conversionRates[currency] = {
  //     Billion: thousandsRates[currency] * 0.000001,
  //     Crore: thousandsRates[currency] * 0.0001,
  //     Million: thousandsRates[currency] * 0.001,
  //     Lakhs: thousandsRates[currency] * 0.01,
  //     Thousands: thousandsRates[currency],
  //     Hundreds: thousandsRates[currency] * 10,
  //   };
  // }
  for (const currency in thousandsRates) {
    conversionRates[currency] = {
      Actual: thousandsRates[currency] * 1, // Direct multiplier of 1
      Hundreds: thousandsRates[currency] * 0.01, // Divide by 100
      Thousands: thousandsRates[currency] * 0.001, // Divide by 1,000
      Lakhs: thousandsRates[currency] * 0.00001, // Divide by 100,000
      Million: thousandsRates[currency] * 0.000001, // Divide by 1,000,000
      Crore: thousandsRates[currency] * 0.0000001, // Divide by 10,000,000
      Billion: thousandsRates[currency] * 0.000000001, // Divide by 1,000,000,000
    };
  }

  useEffect(() => {
    if (financialData?.currencyAndUnits) {
      const years = Object.keys(financialData.currencyAndUnits);
      if (years.length > 0) {
        setCurrency(financialData.currencyAndUnits.currency);
        setUnit(financialData.currencyAndUnits.units);
      }
    }
  }, []);

  useEffect(() => {
    if (financialData?.currency || financialData?.units) {
      setCurrency(financialData?.currency);
      setUnit(financialData?.units);
    }
  }, []);

  useEffect(() => {
    setEditedData(financialData?.data);
  }, [financialData]);

  const toggleEdit = () => {
    setEditable(!editable);
    if (!editable) {
      setEditedData(financialData?.data);
      setEditingYears(Object.fromEntries(years.map((year) => [year, year])));
    } else {
      setEditingYears({});
    }
  };

  const AddMetricModal = () => (
    <Modal
      id="add-metric-modal"
      title="Add New Metric"
      isActive={isAddMetricModalOpen}
      onClose={() => setIsAddMetricModalOpen(false)}
      width="400px"
    >
      <div>
        {/* Container for radio buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Aligns radio buttons left and right
            // justifyContent: "space-evenly",
            marginBottom: "10px", // Space below the radio buttons
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="1"
              checked={newMetricType === "1"}
              onChange={(e) => setNewMetricType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Normal Metric
            </span>{" "}
            {/* Adds space between radio button and text */}
          </label>

          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="2"
              checked={newMetricType === "2"}
              onChange={(e) => setNewMetricType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Nested Metric
            </span>{" "}
            {/* Space and size of text */}
          </label>
        </div>

        {/* Nested levels input (shown only when "Nested Metric" is selected) */}
        {newMetricType === "2" && (
          <div style={{ marginTop: "30px" }}>
            <label style={{ fontSize: "16px" }}>
              Number of nested levels :
              <input
                type="number"
                min="1"
                value={nestedLevels}
                onChange={(e) => setNestedLevels(parseInt(e.target.value, 10))}
                style={{
                  marginLeft: "10px",
                  width: "60px",
                  height: "40px",
                  padding: "10px",
                }}
              />
            </label>
          </div>
        )}

        {/* Submit button */}
        <div
          style={{
            marginTop: "20px",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            type="btn-primary"
            iconRequired={false}
            handleClick={handleAddMetricSubmit}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const handleYearChange = (oldYear, newYear) => {
    // Update the state based on the new year input
    setEditingYears((prev) => ({
      ...prev,
      [oldYear]: newYear === "" ? "" : newYear, // Reset to empty string if input is cleared
    }));
  };

  const handleCellValueChange = (year, key, value) => {
    const updatedData = { ...editedData };
    const keys = key.split(".");
    let data = updatedData[year];
    for (let i = 0; i < keys.length - 1; i++) {
      data = data[keys[i]];
    }
    data[keys[keys.length - 1]] = value;
    setEditedData(updatedData);
  };

  const handleSave = async () => {
    // Process editedData to trim the leading spaces in year keys
    const processEditedData = (data) => {
      const processedData = {};
      Object.keys(data).forEach((year) => {
        const newYear = editingYears[year] || year;
        const trimmedYear = newYear.trimStart(); // Trim leading spaces
        const yearData = data[year];
        processedData[trimmedYear] = {};

        const processObject = (obj, target) => {
          for (let key in obj) {
            const value = obj[key];
            if (typeof value === "object" && value !== null) {
              target[key] = {};
              processObject(value, target[key]);
            } else {
              target[key] = value;
            }
          }
        };

        processObject(yearData, processedData[trimmedYear]);

        // Add new metrics to processedData
        newMetrics.forEach((metric) => {
          processedData[trimmedYear][metric.name] = metric.values[year] || "";
        });
      });
      return processedData;
    };

    const updatedData = {
      sheetType,
      periodType,
      auditStatus,
      editedData: processEditedData(editedData),
    };

    try {
      const res = await putData({
        endpoint: "company/updateExtractedData",
        params: { companyId },
        token: cookies.t,
        data: updatedData,
      });

      if (res.status) {
        toast.success(res.message, {
          style: confimationStyles,
          duration: 1000,
        });
        // Clear new metrics after successful save
        setNewMetrics([]);

        if (handleCancel) {
          handleCancel(companyId, auditStatus, periodType, year, sheetType);
        }
      } else {
        toast.error(res.message, { style: errorStyles, duration: 1000 });
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
    setEditable(false);
  };

  const handleDelete = (id, name) => {
    setModalState({
      isDeleteModalOpen: true,
      modalId: id,
      name: name,
    });
  };
  const myyear = financialData?.data;
  // const extractedYears = Object.keys(myyear);
  const extractedYears = Object.keys(myyear).map((year) => year.trimStart());

  const handleDeleteConfirm = async () => {
    setIsLoadingDel(true); // Set loading to true when API call starts
    // Assuming `years` is an array and you want to extract only the years from some complex object

    try {
      // Add your delete logic here, for example:
      const res = await deleteData({
        endpoint: "company/deleteFinancialDataByYears",
        params: {
          companyId: companyId,
          sheetType: sheetType,
          periodType: periodType,
          auditStatus: auditStatus,
          yearsArray: year || extractedYears,
        },
        token: cookies.t,
      });

      if (res?.data?.status) {
        toast.success("Financial Data Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Delay the reload to ensure the toast is shown for the full duration      } else {
      }
      // Refresh the data after deletion
    } catch (error) {
      console.error(error);
    } finally {
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
      setIsLoadingDel(false);
    }
  };

  const onCancel = () => {
    if (handleCancel) {
      handleCancel(companyId, auditStatus, periodType, year, sheetType);
    }
    setEditable(false);
  };

  const ActualConversionRates = thousandsRates;
  // const ActualConversionRates = {
  //   INR: 1,
  //   USD: 0.012,
  //   EUR: 0.011,
  //   GBP: 0.009,
  //   AED: 0.044,
  // };

  const convertValue = (value, year, currency, unit) => {
    // Check if value is a string and convert to a number
    if (typeof value === "string") {
      value = parseFloat(value.replace(/[^0-9.-]/g, "")); // Remove non-numeric characters except dot and minus
      // value = parseFloat(value.replace(/[^0-9,.-]/g, ""));
    }

    // Check if value is not a number
    if (isNaN(value)) {
      console.error("Invalid value for conversion:", value);
      return "-";
    }

    const currentCurrency = financialData?.currencyAndUnits?.currency;
    const currentUnit = financialData?.currencyAndUnits?.units;

    // If the unit is "Actual", only convert the currency
    // if (currentUnit === "Actual") {
    //   // Convert value to the selected currency
    //   const convertedCurrencyValue =
    //     value *
    //     (ActualConversionRates[currency] /
    //       ActualConversionRates[currentCurrency]);

    //   return parseFloat(convertedCurrencyValue.toFixed(4));
    // }

    if (
      !currentCurrency ||
      !currentUnit ||
      !conversionRates[currentCurrency] ||
      !conversionRates[currentCurrency][currentUnit]
    ) {
      return value;
    }

    const inBaseUnit = value / conversionRates[currentCurrency][currentUnit];
    const convertedValue =
      inBaseUnit * (conversionRates[currency]?.[unit] || 1);

    return parseFloat(convertedValue.toFixed(2));
  };

  const handleCurrencyChange = (selectedOption) => {
    setCurrency(selectedOption);
  };

  const handleUnitChange = (selectedOption) => {
    setUnit(selectedOption);
  };

  const getAllKeys = (data) => {
    let keys = new Set();
    for (let year in data) {
      const yearData = data[year];
      const extractKeys = (obj, parentKey = "") => {
        for (let key in obj) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          keys.add(newKey);
          if (typeof obj[key] === "object" && obj[key] !== null) {
            extractKeys(obj[key], newKey);
          }
        }
      };
      extractKeys(yearData);
    }
    return Array.from(keys);
  };

  const years = Object.keys(financialData?.data || {});
  const allKeys = getAllKeys(financialData?.data || {});

  const groupKeysByParent = (keys) => {
    const groupedKeys = {};
    keys.forEach((key) => {
      // Skip keys with space after dot
      if (key.includes(". ") || key.includes(" .")) {
        groupedKeys[key] = null;
        return;
      }

      const parts = key.split(".");
      let current = groupedKeys;
      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = index === parts.length - 1 ? null : {};
        }
        current = current[part];
      });
    });
    return groupedKeys;
  };

  const groupedKeys = groupKeysByParent(allKeys);

  const getNestedValue = (data, key) => {
    const keys = key.split(".");
    let value = data;
    for (let k of keys) {
      if (value === undefined || value === null) return "";
      value = value[k];
    }
    return value;
  };

  const handleDeleteRow = (fullKey) => {
    setRowToDelete(fullKey);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteRow = () => {
    if (!rowToDelete) return;

    setEditedData((prevData) => {
      const newData = { ...prevData };
      years.forEach((year) => {
        const keys = rowToDelete.split(".");
        let current = newData[year];
        for (let i = 0; i < keys.length - 1; i++) {
          if (current[keys[i]] === undefined) {
            return newData; // Exit if the path doesn't exist
          }
          current = current[keys[i]];
        }
        delete current[keys[keys.length - 1]];
      });
      return newData;
    });

    toast.success("Row deleted. Changes will be applied when you save.", {
      style: confimationStyles,
      duration: 2000,
    });

    setIsDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleNewMetricNameChange = (parentKey, oldKey, newKey) => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData));
      years.forEach((year) => {
        let current = newData[year];
        const keys = parentKey ? parentKey.split(".") : [];
        for (let key of keys) {
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }
        if (newKey && newKey !== oldKey) {
          current[newKey] = current[oldKey];
          delete current[oldKey];
        }
      });
      return newData;
    });
  };

  // const handleAddNestedRow = (parentKey) => {
  //   const metricType = prompt(
  //     "Choose metric type:\n1. Normal Metric\n2. Nested Metric",
  //     "1",
  //   );

  //   if (metricType !== "1" && metricType !== "2") {
  //     toast.error("Invalid choice. Please choose 1 or 2.");
  //     return;
  //   }

  //   let nestedLevels = 1;
  //   if (metricType === "2") {
  //     const levelsInput = prompt("Enter the number of nested levels:", "1");
  //     nestedLevels = parseInt(levelsInput, 10);
  //     if (isNaN(nestedLevels) || nestedLevels < 1) {
  //       toast.error("Please enter a valid number of nested levels.");
  //       return;
  //     }
  //   }

  //   setEditedData((prevData) => {
  //     const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

  //     const createNestedStructure = (depth) => {
  //       if (depth === 0) return "";
  //       const nestedStructure = {};
  //       nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
  //         depth - 1,
  //       );
  //       return nestedStructure;
  //     };

  //     years.forEach((year) => {
  //       let current = newData[year];
  //       const keys = parentKey.split(".");
  //       for (let key of keys) {
  //         if (!current[key]) {
  //           current[key] = {};
  //         }
  //         current = current[key];
  //       }

  //       if (metricType === "1") {
  //         // Normal metric
  //         let index = 1;
  //         while (current[`New Item ${index}`] !== undefined) {
  //           index++;
  //         }
  //         const newItemKey = `New Item ${index}`;
  //         current[newItemKey] = "";
  //       } else {
  //         // Nested metric
  //         const timestamp = Date.now(); // Generate a unique timestamp
  //         current[`New Nested Structure ${timestamp}`] =
  //           createNestedStructure(nestedLevels);
  //       }
  //     });

  //     return newData;
  //   });
  // };
  const handleAddNestedRow = (parentKey) => {
    setCurrentParentKey(parentKey);
    setIsAddNestedRowModalOpen(true);
  };

  const handleAddNestedRowSubmit = () => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

      const createNestedStructure = (depth) => {
        if (depth === 0) return "";
        const nestedStructure = {};
        nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
          depth - 1,
        );
        return nestedStructure;
      };

      years.forEach((year) => {
        let current = newData[year];
        const keys = currentParentKey.split(".");
        for (let key of keys) {
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }

        if (newNestedRowType === "1") {
          // Normal metric
          let index = 1;
          while (current[`New Item ${index}`] !== undefined) {
            index++;
          }
          const newItemKey = `New Item ${index}`;
          current[newItemKey] = "";
        } else {
          // Nested metric
          const timestamp = Date.now(); // Generate a unique timestamp
          current[`New Nested Structure ${timestamp}`] =
            createNestedStructure(nestedLevels);
        }
      });

      return newData;
    });

    setIsAddNestedRowModalOpen(false);
    setNewNestedRowType("1");
    setNestedLevels(1);
    setCurrentParentKey("");
  };

  const AddNestedRowModal = () => (
    <Modal
      id="add-nested-row-modal"
      title="Add New Nested Row"
      isActive={isAddNestedRowModalOpen}
      onClose={() => setIsAddNestedRowModalOpen(false)}
      width="400px"
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Aligns radio buttons left and right
            // justifyContent: "space-evenly",
            marginBottom: "10px", // Space below the radio buttons
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="1"
              checked={newNestedRowType === "1"}
              onChange={(e) => setNewNestedRowType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Normal Metric
            </span>{" "}
            {/* Adds space between radio button and text */}
          </label>

          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="2"
              checked={newNestedRowType === "2"}
              onChange={(e) => setNewNestedRowType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Nested Metric
            </span>{" "}
            {/* Space and size of text */}
          </label>
        </div>
        {newNestedRowType === "2" && (
          <div style={{ marginTop: "30px" }}>
            <label style={{ fontSize: "16px" }}>
              Number of nested levels :
              <input
                type="number"
                min="1"
                value={nestedLevels}
                onChange={(e) => setNestedLevels(parseInt(e.target.value, 10))}
                style={{
                  marginLeft: "10px",
                  width: "60px",
                  height: "40px",
                  padding: "10px",
                }}
              />
            </label>
          </div>
        )}
        <div
          style={{
            marginTop: "20px",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            type="btn-primary"
            iconRequired={false}
            handleClick={handleAddNestedRowSubmit}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const renderRows = (data, parentKey = "", depth = 0) => {
    const rows = Object.entries(data).flatMap(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const isObject = typeof value === "object" && value !== null;
      const isNewRow = key.startsWith("New") || key.startsWith("Metrics");

      const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

      const currentRow = (
        <tr key={fullKey}>
          <td
            style={{
              paddingLeft,
              fontWeight: isObject ? "bold" : "normal",
            }}
          >
            {isNewRow && editable ? (
              <input
                type="text"
                id={`${parentKey}-${key}`}
                // value={key}
                onBlur={(e) =>
                  handleNewMetricNameChange(parentKey, key, e.target.value)
                }
                style={{ width: "100%" }}
              />
            ) : (
              key
            )}
            {isObject && editable && (
              <button
                onClick={() => handleAddNestedRow(fullKey)}
                style={{
                  marginLeft: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "green",
                  fontSize: "1.2em",
                }}
              >
                <Add color="black" />
              </button>
            )}
          </td>
          {years.map((year) => (
            <td key={`${year}-${fullKey}`}>
              {isObject ? (
                ""
              ) : editable ? (
                <input
                  type="text"
                  value={getNestedValue(editedData[year], fullKey) || ""}
                  onChange={(e) =>
                    handleCellValueChange(year, fullKey, e.target.value)
                  }
                  // onFocus={() => setFocusedInput(`${year}-${fullKey}`)}
                  // onBlur={() => setFocusedInput(null)}
                  // autoFocus={focusedInput === `${year}-${fullKey}`}
                />
              ) : // convertValue(
              //   getNestedValue(editedData[year], fullKey)?.toString() || "-",
              //   year.trimStart(),
              //   currency,
              //   unit,
              // )
              excurrency ? (
                getNestedValue(editedData[year], fullKey) ? (
                  formatValue(
                    getNestedValue(editedData[year], fullKey),
                    unit,
                    currency,
                  )
                ) : (
                  getNestedValue(editedData[year], fullKey)
                )
              ) : convertValue(
                  getNestedValue(editedData[year], fullKey)?.toString() || "-",
                  year.trimStart(),
                  currency,
                  unit,
                ) ? (
                formatValue(
                  convertValue(
                    getNestedValue(editedData[year], fullKey)?.toString() ||
                      "-",
                    year.trimStart(),
                    currency,
                    unit,
                  ),
                  unit,
                  currency,
                )
              ) : (
                convertValue(
                  getNestedValue(editedData[year], fullKey)?.toString() || "-",
                  year.trimStart(),
                  currency,
                  unit,
                )
              )}
            </td>
          ))}
          {editable && (
            <td style={{ border: "none" }}>
              <button
                onClick={() => handleDeleteRow(fullKey)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "red",
                  fontSize: "1.2em",
                }}
              >
                <Delete />
              </button>
            </td>
          )}
        </tr>
      );

      if (isObject) {
        return [currentRow, ...renderRows(value, fullKey, depth + 1)];
      }

      return currentRow;
    });

    // Add new metrics
    if (depth === 0 && editable) {
      newMetrics.forEach((metric, index) => {
        rows.push(
          <tr key={`new-metric-${index}`}>
            <td style={{ paddingLeft: "10px" }}>
              <input
                type="text"
                value={metric.name}
                onChange={(e) =>
                  handleNewMetricChange(index, "name", e.target.value)
                }
                placeholder="New Metric Name"
              />
            </td>
            {years.map((year) => (
              <td key={`new-metric-${index}-${year}`}>
                <input
                  type="text"
                  value={metric.values[year] || ""}
                  onChange={(e) =>
                    handleNewMetricChange(index, year, e.target.value)
                  }
                  placeholder="Value"
                />
              </td>
            ))}
            <td style={{ border: "none" }}>
              <button
                onClick={() => {
                  const updatedMetrics = newMetrics.filter(
                    (_, i) => i !== index,
                  );
                  setNewMetrics(updatedMetrics);
                }}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: "red",
                  fontSize: "1.2em",
                }}
              >
                <Delete />
              </button>
            </td>
          </tr>,
        );
      });
    }

    return rows;
  };
  const containerStyle = {
    margin: edit ? "" : "0",
  };

  const logConvertedData1 = () => {
    if (!financialData?.data) return;

    const convertedData = {};
    const years = Object.keys(financialData.data);

    years.forEach((year) => {
      const yearData = financialData.data[year];
      convertedData[year] = {};

      const processObject = (obj, target) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            target[key] = {};
            processObject(value, target[key]);
          } else {
            target[key] = convertValue(value, year.trimStart(), currency, unit);
          }
        }
      };

      processObject(yearData, convertedData[year]);
    });

    if (onDataConverted1) {
      // Store converted data based on sheet type
      onDataConverted1({ sheetType, data: convertedData, currency, unit });
    }
  };
  const logConvertedData2 = () => {
    if (!financialData?.data) return;

    const convertedData = {};
    const years = Object.keys(financialData.data);

    years.forEach((year) => {
      const yearData = financialData.data[year];
      convertedData[year] = {};

      const processObject = (obj, target) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            target[key] = {};
            processObject(value, target[key]);
          } else {
            target[key] = convertValue(value, year.trimStart(), currency, unit);
          }
        }
      };

      processObject(yearData, convertedData[year]);
    });

    if (onDataConverted2) {
      // Store converted data based on sheet type
      onDataConverted2({ sheetType, data: convertedData, currency, unit });
    }
  };

  useEffect(() => {
    logConvertedData1();
    logConvertedData2();
  }, [currency, unit, currencyNew, unitsNew, currencyNew, excurrency, exunit]);

  // const handleAddMetric = () => {
  //   setIsAddMetricModalOpen(true);
  //   const metricType = prompt(
  //     "Choose metric type:\n1. Normal Metric\n2. Nested Metric",
  //     "1",
  //   );

  //   if (metricType !== "1" && metricType !== "2") {
  //     toast.error("Invalid choice. Please choose 1 or 2.");
  //     return;
  //   }

  //   let nestedLevels = 1;
  //   if (metricType === "2") {
  //     const levelsInput = prompt("Enter the number of nested levels:", "1");
  //     nestedLevels = parseInt(levelsInput, 10);
  //     if (isNaN(nestedLevels) || nestedLevels < 1) {
  //       toast.error("Please enter a valid number of nested levels.");
  //       return;
  //     }
  //   }

  //   setEditedData((prevData) => {
  //     const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

  //     const createNestedStructure = (depth) => {
  //       if (depth === 0) return "";
  //       const nestedStructure = {};
  //       nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
  //         depth - 1,
  //       );
  //       return nestedStructure;
  //     };

  //     years.forEach((year) => {
  //       if (metricType === "1") {
  //         // Normal metric
  //         let index = 1;
  //         while (newData[year][`New Metric ${index}`] !== undefined) {
  //           index++;
  //         }
  //         const newMetricKey = `New Metric ${index}`;
  //         newData[year][newMetricKey] = "";
  //       } else {
  //         // Nested metric
  //         const timestamp = Date.now(); // Generate a unique timestamp
  //         if (!newData[year].Metrics) {
  //           newData[year].Metrics = {};
  //         }
  //         newData[year].Metrics[`New Nested Structure ${timestamp}`] =
  //           createNestedStructure(nestedLevels);
  //       }
  //     });

  //     return newData;
  //   });
  // };

  const handleAddMetric = () => {
    setIsAddMetricModalOpen(true);
  };

  const handleAddMetricSubmit = () => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

      const createNestedStructure = (depth) => {
        if (depth === 0) return "";
        const nestedStructure = {};
        nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
          depth - 1,
        );
        return nestedStructure;
      };

      years.forEach((year) => {
        if (newMetricType === "1") {
          // Normal metric
          let index = 1;
          while (newData[year][`New Metric ${index}`] !== undefined) {
            index++;
          }
          const newMetricKey = `New Metric ${index}`;
          newData[year][newMetricKey] = "";
        } else {
          // Nested metric
          const timestamp = Date.now(); // Generate a unique timestamp
          if (!newData[year].Metrics) {
            newData[year].Metrics = {};
          }
          newData[year].Metrics[`New Nested Structure ${timestamp}`] =
            createNestedStructure(nestedLevels - 1);
        }
      });

      return newData;
    });

    setIsAddMetricModalOpen(false);
    setNewMetricType("1");
    setNestedLevels(1);
  };

  const handleNewMetricChange = (index, field, value) => {
    const updatedMetrics = [...newMetrics];
    if (field === "name") {
      updatedMetrics[index].name = value;
    } else {
      updatedMetrics[index].values[field] = value;
    }
    setNewMetrics(updatedMetrics);
  };

  return (
    <>
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          id={modalState.modalId}
          name={`data of ${sheetType} of years ${
            JSON.stringify(year) || JSON.stringify(extractedYears)
          }`}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Financial Data"
          onClick={handleDeleteConfirm} // Directly pass the confirm handler without arguments
        />
      )}
      <div className="table-container" style={containerStyle}>
        {edit && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontWeight: 600,
                marginBottom: "30px",
                fontSize: "28px",
                alignItems: "center",
              }}
            >
              {/* Table */}
              {sheetType === "balance_sheet"
                ? "Balance Sheet Table"
                : sheetType === "pl"
                ? "Profit and Loss Table"
                : "Table"}
            </div>

            {!editable && (
              <div style={{ display: "flex", gap: "20px" }}>
                <CustomButton
                  type="btn-primary"
                  disabled={isLoadingDel}
                  iconRequired={false}
                  handleClick={() => handleDelete()}
                >
                  Delete
                  {isLoadingDel && (
                    <div
                      className="spinner-border spinner-border-sm text-light ms-3"
                      role="status"
                    ></div>
                  )}
                </CustomButton>
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => toggleEdit()}
                >
                  Edit
                </CustomButton>
              </div>
            )}
          </div>
        )}

        {preview && (
          <div className="d-flex mb-3 align-items-center">
            <div style={{ width: "200px", marginRight: "10px" }}>
              <SelectDropdown
                selectedValue={currency}
                onChange={handleCurrencyChange}
                data={currencies}
                placeholder="Select Currency"
              />
            </div>

            {currency && unit && (
              <>
                <div>in</div>
                <div style={{ width: "200px", marginLeft: "10px" }}>
                  <SelectDropdown
                    selectedValue={unit}
                    onChange={handleUnitChange}
                    data={units}
                    placeholder="Select Unit"
                  />
                </div>
              </>
            )}
          </div>
        )}

        {years.length > 0 ? (
          <>
            <div style={{ overflowX: "scroll" }}>
              <table>
                <thead style={{ background: "#f2f2f2" }}>
                  <tr>
                    <th>
                      {sheetType === "pl"
                        ? "Profit and Loss"
                        : sheetType === "balance_sheet"
                        ? "Balance Sheet"
                        : sheetType}
                      {/* if extracted== true then show this{" "}
                      {`(${excurrency} in ${exunit})`}
                      if view === true then show this{" "}
                      {`(${currencyNew} in ${unitsNew})`}
                      if botg is nne then {`(${currency} in ${unit})`} */}

                      {extracted === true
                        ? `(${excurrency} in ${exunit})`
                        : view === true
                        ? `(${currencyNew} in ${unitsNew})`
                        : `(${currency} in ${unit})`}
                    </th>

                    {years.map((year) => (
                      <th key={year}>
                        {editable ? (
                          <input
                            type="text"
                            value={editingYears[year]}
                            onChange={(e) =>
                              handleYearChange(year, e.target.value)
                            }
                            style={{ width: "100%", boxSizing: "border-box" }}
                          />
                        ) : (
                          year
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* <tbody>{renderRows(groupedKeys)}</tbody> */}
                <tbody>{renderRows(editedData?.[years?.[0]] || {})}</tbody>
              </table>
            </div>
            {isAddMetricModalOpen && <AddMetricModal />}
            {isAddNestedRowModalOpen && <AddNestedRowModal />}

            {editable && (
              <div style={{ marginTop: "10px", display: "flex", gap: "25px" }}>
                <CustomButton
                  type="btn-primary"
                  iconRequired={true}
                  handleClick={handleAddMetric}
                >
                  Add New Metric
                </CustomButton>
                {/* <CustomButton
                  type="btn-primary"
                  iconRequired={true}
                  handleClick={handleAddNestedMetric}
                >
                  Add New Nested Metric
                </CustomButton> */}
              </div>
            )}
            {editable && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => handleSave()}
                >
                  Save
                </CustomButton>

                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => onCancel()}
                >
                  Cancel
                </CustomButton>

                {isDeleteModalOpen && (
                  <DeleteModal
                    isActive={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title="Financial Data Row"
                    name={`metric ${rowToDelete}`}
                    onClick={confirmDeleteRow}
                    type="Delete"
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
};

export default FinancialDataTable;
